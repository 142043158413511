import { useEffect, useRef, useState } from "react";
/* import { Link as RouterLink } from "react-router-dom"; */
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CategoriesUrl, storageUrl } from "../../../constants/urls";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export default function CategoriesMoreMenu({
  id,
  token,
  setLoadingUserBtn,
  loadingUserBtn,
  apiKey,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState("");

  const [EnName, setEnName] = useState();
  const [ArName, setArName] = useState();
  const [KuName, setKuName] = useState();
  const [Sort, setSort] = useState();
  const [UserImageToShow, setUserImageToShow] = useState();
  const [previewUserImage, setPreviewUserImage] = useState(null);
  const [UserImageToUpload, setUserImageToUpload] = useState(null);

  async function fecthData() {
    await axios
      .get(`${CategoriesUrl}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          api_key: apiKey,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
          setEnName(response.data.name_array.en);
          setArName(response.data.name_array.ar);
          setKuName(response.data.name_array.ku);
          setSort(response.data.sort);
          setUserImageToShow(response.data.image);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  useEffect(() => {
    fecthData();
  }, []);
  /* 
     Delete user
  */
  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const handleClickOpenDeleteUser = () => {
    setOpenDeleteUser(true);
  };

  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false);
  };

  const handleSubmitDeleteUser = () => {
    setLoadingUserBtn(true);
    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      api_key: apiKey,
    };
    axios
      .delete(`${CategoriesUrl}/${id}`, {
        headers,
      })
      .then((response) => {
        setOpenDeleteUser(false);
        setIsOpen(false);
        Swal.fire({
          text: "Category Deleted successfully!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            /* window.location.reload(); */
            setLoadingUserBtn(false);
          }
        });
      })
      .catch((error) => {
        setLoadingUserBtn(false);
        setIsOpen(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      });
  };

  /* 
     Update User
  */

  const [openEditUser, setOpenEditUser] = useState(false);

  const handleChangeEnName = (event) => {
    setEnName(event.target.value);
  };

  const handleChangeArName = (event) => {
    setArName(event.target.value);
  };

  const handleChangeKuName = (event) => {
    setKuName(event.target.value);
  };

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const handleClickOpenEditUser = () => {
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleCaptureUserImage = (e) => {
    setUserImageToShow(null);
    setUserImageToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewUserImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmitEditUser = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();

    formData.append("code", EnName);
    formData.append("name[en]", EnName);
    formData.append("name[ar]", ArName);
    formData.append("name[ku]", KuName);
    formData.append("sort", Sort);
    formData.append("group_category", 0);
    UserImageToUpload !== null && formData.append("image", UserImageToUpload);

    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "content-type": "multipart/form-data",
      api_key: apiKey,
    };
    axios
      .post(`${CategoriesUrl}/${id}?_method=PUT`, formData, {
        headers,
      })
      .then((response) => {
        setLoadingUserBtn(false);
        setOpenEditUser(false);
        setIsOpen(false);
        Swal.fire({
          text: "Category Updated successfully!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            /* window.location.reload(); */
            setLoadingUserBtn(false);
          }
        });
      })
      .catch((error) => {
        setLoadingUserBtn(false);
        setIsOpen(false);
        setOpenEditUser(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* Edit user */}
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={handleClickOpenEditUser}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-2-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit Category"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <Dialog
          disableEscapeKeyDown
          open={openEditUser}
          onClose={handleCloseEditUser}
        >
          <DialogTitle>Edit Category {user.name}</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category English Name"
                  variant="outlined"
                  onChange={handleChangeEnName}
                  value={EnName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category Arabic Name"
                  variant="outlined"
                  type={"text"}
                  onChange={handleChangeArName}
                  value={ArName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category Kurdish Name"
                  variant="outlined"
                  type={"text"}
                  onChange={handleChangeKuName}
                  value={KuName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Sort"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeSort}
                  value={Sort}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>Category Image</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewUserImage ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewUserImage}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewUserImage(null)}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      Upload Category Image
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureUserImage}
                      />
                    </Button>
                  )}
                  {UserImageToShow && (
                    <Box className="image-item" sx={{ margin: "1rem 0" }}>
                      <Avatar
                        src={`${storageUrl}${UserImageToShow}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setUserImageToShow(null)}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseEditUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitEditUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Delete user */}
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={handleClickOpenDeleteUser}
        >
          <ListItemIcon>
            <Iconify icon="ic:baseline-delete" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete Category"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <Dialog
          disableEscapeKeyDown
          open={openDeleteUser}
          onClose={handleCloseDeleteUser}
        >
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            Are you sure you want to delete {user.name} ?
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseDeleteUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitDeleteUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
