// material
import { Box, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { t } = useTranslation();
  const token = localStorage.getItem("Ftkn");
  let navigate = useNavigate();

  useEffect(() => {
    if (token === null) {
      navigate("/");
    }
  }, [token]);

  return (
    <Page title="Fuul w Huumus |Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Typography variant="h4">
            {t("description.dashboardAppWelcome")}{" "}
            {localStorage.getItem("FusrNm")}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
}
