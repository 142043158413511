import { filter } from "lodash";
import axios from "axios";
import { storageUrl, CategoriesUrl, apiKey } from "../constants/urls";

/* import { sentenceCase } from "change-case"; */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Box,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  LinearProgress,
  Avatar,
} from "@mui/material";

// components

import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  CategoriesMoreMenu,
  UserListHead,
  UserListToolbar,
} from "../sections/@dashboard/user";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Categories() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openNewUser, setOpenNewUser] = useState(false);
  const [loadingUserBtn, setLoadingUserBtn] = useState(false);
  const [EnName, setEnName] = useState();
  const [ArName, setArName] = useState();
  const [KuName, setKuName] = useState();
  const [Sort, setSort] = useState();

  const [previewUserImage, setPreviewUserImage] = useState(null);
  const [UserImageToUpload, setUserImageToUpload] = useState(null);

  const [USERLIST, setUSERLIST] = useState();

  let navigate = useNavigate();
  const token = localStorage.getItem("Ftkn");
  async function fecthData() {
    if (token === null) {
      navigate("/");
    } else {
      await axios
        .get(`${CategoriesUrl}`, {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            api_key: apiKey,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setUSERLIST(response.data);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }
  useEffect(() => {
    fecthData();
  }, [loadingUserBtn]);

  if (USERLIST === undefined) {
    return <LinearProgress />;
  }
  let filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );
  const TABLE_HEAD = [
    {
      id: "name",
      label: "Category Name",
      alignRight: false,
    },

    {
      id: "sort",
      label: "Sort",
      alignRight: false,
    },
    { id: "" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangeEnName = (event) => {
    setEnName(event.target.value);
  };

  const handleChangeArName = (event) => {
    setArName(event.target.value);
  };

  const handleChangeKuName = (event) => {
    setKuName(event.target.value);
  };

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const handleClickOpenNewUser = () => {
    setOpenNewUser(true);
  };

  const handleCloseNewUser = () => {
    setOpenNewUser(false);
  };

  const handleCaptureUserImage = (e) => {
    setUserImageToUpload(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPreviewUserImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmitAddUser = () => {
    setLoadingUserBtn(true);
    const formData = new FormData();
    formData.append("code", EnName);
    formData.append("name[en]", EnName);
    formData.append("name[ar]", ArName);
    formData.append("name[ku]", KuName);
    formData.append("sort", Sort);
    formData.append("group_category", 0);

    UserImageToUpload !== null && formData.append("image", UserImageToUpload);

    const headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      api_key: apiKey,
    };
    axios
      .post(`${CategoriesUrl}`, formData, {
        headers,
      })
      .then((response) => {
        setLoadingUserBtn(false);
        setOpenNewUser(false);
        setEnName("");
        setArName("");
        setKuName("");
        setSort("");
        setPreviewUserImage(null);
        setUserImageToUpload(null);
        Swal.fire({
          text: "Category added successfully!",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setLoadingUserBtn(false);
          }
        });
      })
      .catch((error) => {
        setLoadingUserBtn(false);
        setOpenNewUser(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      });
  };

  return (
    <Page title="Dashboard | Categories">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          <Button variant="contained" onClick={handleClickOpenNewUser}>
            Add New
          </Button>
        </Stack>
        <Dialog
          disableEscapeKeyDown
          open={openNewUser}
          onClose={handleCloseNewUser}
        >
          <DialogTitle>Add New Category</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category English Name"
                  variant="outlined"
                  onChange={handleChangeEnName}
                  value={EnName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category Arabic Name"
                  variant="outlined"
                  type={"text"}
                  onChange={handleChangeArName}
                  value={ArName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Category Kurdish Name"
                  variant="outlined"
                  type={"text"}
                  onChange={handleChangeKuName}
                  value={KuName}
                />
              </FormControl>
            </Box>
            <Box component="form">
              <FormControl sx={{ m: 1, minWidth: 350 }}>
                <TextField
                  id="outlined-basic"
                  label="Sort"
                  variant="outlined"
                  type={"number"}
                  onChange={handleChangeSort}
                  value={Sort}
                />
              </FormControl>
            </Box>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                width: "90%",
                marginTop: "1rem",
              }}
            >
              <Typography>Category Image</Typography>
              <FormControl sx={{ m: 1, maxWidth: "100%" }}>
                <Box className="upload__image-wrapper">
                  {previewUserImage ? (
                    <Box className="image-item">
                      <Avatar
                        src={`${previewUserImage}`}
                        alt=""
                        sx={{ width: 200, height: 200, margin: "0 auto" }}
                      />
                      <Box className="image-item__btn-wrapper">
                        <Button
                          sx={{ margin: "1rem 0" }}
                          variant="outlined"
                          onClick={() => setPreviewUserImage(null)}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      sx={{ margin: "1rem 0" }}
                      variant="outlined"
                      component="label"
                    >
                      Upload Image
                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        onChange={handleCaptureUserImage}
                      />
                    </Button>
                  )}
                </Box>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleCloseNewUser}>
              {t("description.Cancel")}
            </Button>
            <LoadingButton
              loading={loadingUserBtn}
              onClick={handleSubmitAddUser}
            >
              {t("description.Ok")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <UserListToolbar
              placeHolder="Search by name ..."
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={name}
                                src={`${storageUrl}${row.image}`}
                                sx={{ width: "80px", height: "80px" }}
                              />
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  margin:
                                    i18n.dir() === "rtl" &&
                                    "0 0.5rem !important",
                                }}
                              >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align={i18n.dir() === "ltr" ? "left" : "right"}
                          >
                            {row.sort}
                          </TableCell>
                          <TableCell
                            align={i18n.dir() === "ltr" ? "right" : "left"}
                            padding="none"
                          >
                            <CategoriesMoreMenu
                              id={id}
                              token={token}
                              apiKey={apiKey}
                              loadingUserBtn={loadingUserBtn}
                              setLoadingUserBtn={setLoadingUserBtn}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            sx={{ direction: "ltr" }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("description.UsersPageLabelRowsPerPage")}
          />
        </Card>
      </Container>
    </Page>
  );
}
